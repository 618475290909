
.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.Footer {
  padding: 0.75rem;
  display: grid;
  place-content: center;
  margin-top: auto;
}
